import React from "react";
import Img from "gatsby-image";

import styles from "./SectionImages.module.scss";

const SectionImages = ({ section }) => {
  return (
    <div className={styles.self}>
      {section.image_list.map(({ first_image, first_alt, second_image }) => (
        <div className={styles.row}>
          {!second_image ? (
            <Img
              className={styles.oneImage}
              fluid={first_image.childImageSharp.fluid}
              alt={first_alt}
            />
          ) : (
            <div className={styles.twoImages}>
              <Img fluid={first_image.childImageSharp.fluid} alt={first_alt} />
              <Img fluid={second_image.childImageSharp.fluid} alt={first_alt} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default SectionImages;
