import React from "react";
import ReactMarkdown from "react-markdown";

import styles from "./SectionDescription.module.scss";

const SectionDescription = ({ section: { description, values } }) => {
  return (
    <div className={styles.self}>
      {values && (
        <div className={styles.highlights}>
          {values.map(pair => (
            <div className={styles.row}>
              <span className={styles.key}>{pair.key}</span>
              <span className={styles.value}>{pair.value}</span>
            </div>
          ))}
        </div>
      )}
      {description && (
        <div className={styles.description}>
          <ReactMarkdown source={description} />
        </div>
      )}
    </div>
  );
};

export default SectionDescription;
