import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../../components/Layout";
import Section from "../../components/Section";

export const query = graphql`
  query ProjectQuery($id: Int!) {
    strapiProject(strapiId: { eq: $id }) {
      strapiId
      name
      seo_description
      main_img {
        publicURL
        childImageSharp {
          fluid(quality: 90, maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      menu_image {
        publicURL
      }
      section {
        id
        name
        description
        values {
          key
          value
        }
        image_list {
          first_alt
          first_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          second_image {
            childImageSharp {
              fluid(quality: 90, maxWidth: 1440) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const Project = ({ data }) => {
  const project = data.strapiProject;
  const seoOptions = {
    title: project.name,
    description: project.seo_description || "",
    ogImage:
      (project.main_img && project.main_img.publicURL) ||
      (project.menu_img && project.menu_img.publicURL),
  };

  return (
    <Layout seoOptions={seoOptions}>
      {project.main_img && (
        <Img fluid={project.main_img.childImageSharp.fluid} />
      )}
      <div>
        {project.section.map(section => (
          <Section key={section.id} section={section} />
        ))}
      </div>
    </Layout>
  );
};

export default Project;
