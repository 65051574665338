import React from "react";

import SectionDescription from "./SectionDescription";
import SectionImages from "./SectionImages";

import styles from "./Section.module.scss";

const Section = ({ section }) => {
  return (
    <div className={styles.self}>
      <h2 className={styles.name}>{section.name}</h2>
      <div>
        {(section.description || section.values) && (
          <SectionDescription section={section} />
        )}
        {section.image_list && <SectionImages section={section} />}
      </div>
    </div>
  );
};

export default Section;
